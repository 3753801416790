import moment from 'moment'
import { BATCH_EDIT_ACTION } from '../../components/App/constants/appConstants'
import { BlueprintCampaign } from '../signs/SignResponse.model'
import { PogSetDate } from './ProjectDetail.model'

export default class SapTableProjectRequest {
  request?: SapProjectRequest
  id?: string
  action: BATCH_EDIT_ACTION

  constructor(props: any = {}) {
    this.request = props.request
      ? new SapProjectRequest(props.request)
      : new SapProjectRequest()
    this.id = props.id
    this.action = props.action || BATCH_EDIT_ACTION.ADD
  }
}

export class SapProjectRequest {
  blueprint_id: string
  project_id?: string
  short_name: string
  campaign_id: string
  creative_assets: boolean
  non_adjacency_project: boolean
  set_date: string
  nrsc_receive_date?: string
  in_store_date?: string
  pog_set_date: PogSetDate
  division_ids: number[]
  department_ids: number[]
  project_type: string
  tactic: string
  fund_id: string
  initial_budget: number
  campaign: BlueprintCampaign
  merchant_contact?: string
  updated_by: string
  import_sap_project_ids?: string[]

  constructor(props: any = {}) {
    this.blueprint_id = props.blueprint_id || ''
    this.project_id = props.project_id
    this.short_name = props.short_name || ''
    this.campaign_id = props.campaign_id || ''
    this.creative_assets = props.creative_assets || false
    this.non_adjacency_project = props.non_adjacency_project || false
    this.set_date = props.set_date || moment(new Date()).format('YYYY-MM-DD')
    this.in_store_date = props.in_store_date
    this.nrsc_receive_date = props.nrsc_receive_date
    this.pog_set_date = props.pog_set_date || new PogSetDate()
    this.division_ids = props.division_ids || []
    this.department_ids = props.department_ids || []
    this.project_type = props.project_type || ''
    this.tactic = props.tactic || ''
    this.fund_id = props.fund_id || ''
    this.initial_budget = props.initial_budget || 0
    this.campaign = props.campaign
      ? new BlueprintCampaign(props.campaign)
      : new BlueprintCampaign()
    this.merchant_contact = props.merchant_contact
    this.updated_by = props.updated_by || 'system'
    this.import_sap_project_ids = props.import_sap_project_ids
  }
}
