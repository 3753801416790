import { Column } from '../../../../../models/agGrid/AgGridColumn.model'

export const BLUEPRINT_PROJECT_LIST = [
  new Column({
    type: ['selectedRow', 'fixed'],
  }),
  new Column({
    type: ['signMenu', 'fixed'],
  }),
  new Column({
    field: 'sap_project_id',
    headerName: 'PID',
    width: 180,
    type: ['pidCell', 'fixed', 'popUpCell'],
  }),
  new Column({
    field: 'name',
    headerName: 'SAP Project Name',
    width: 250,
    type: ['projectDetailLink', 'fixed'],
  }),
  new Column({
    field: 'set_date',
    headerName: 'Set Date\u002A',
    width: 162,
    type: ['setDate', 'dateEditor', 'fixed', 'popUpCell', 'requiredField'],
    filter: 'agDateColumnFilter',
  }),
  new Column({
    field: 'short_name',
    headerName: 'Project Name\u002A',
    width: 250,
    type: ['textEditor', 'requiredField'],
  }),
  new Column({
    field: 'project_type',
    headerName: 'Project Type\u002A',
    width: 190,
    type: ['selectEditor', 'requiredField'],
    filter: 'agSetColumnFilter',
  }),
  new Column({
    field: 'campaign',
    colId: 'campaign',
    headerName: 'Campaign Type\u002A',
    width: 194,
    type: ['campaignType', 'campaignEditor', 'requiredField'],
    filter: 'agSetColumnFilter',
  }),
  new Column({
    field: 'campaign.name',
    headerName: 'Campaign Type',
    type: ['hiddenColumn'],
  }),
  new Column({
    field: 'campaign_id',
    headerName: 'Campaign ID\u002A',
    width: 175,
    type: ['requiredField'],
  }),
  new Column({
    field: 'initial_budget',
    headerName: 'Budget\u002A',
    type: ['numberEditor', 'requiredField'],
    filter: 'agNumberColumnFilter',
  }),
  new Column({
    field: 'tactic',
    headerName: 'Tactic\u002A',
    width: 172,
    type: ['selectEditor', 'requiredField'],
    filter: 'agSetColumnFilter',
  }),
  new Column({
    field: 'fund_id',
    colId: 'fund_id',
    headerName: 'Fund ID\u002A',
    width: 256,
    type: ['fundName', 'selectEditor', 'requiredField'],
    filter: 'agSetColumnFilter',
  }),
  new Column({
    field: 'creative_assets',
    headerName: 'Creative Content',
    width: 218,
    type: ['toggle', 'toggleEditor'],
  }),
  new Column({
    field: 'non_adjacency_project',
    headerName: 'Non-Adjacency Project',
    width: 278,
    type: ['toggle', 'toggleEditor'],
  }),
  new Column({
    field: 'pog_set_date',
    headerName: 'POG Selection Options',
    width: 270,
    type: ['pogSelection', 'pogSelectionEditor', 'popUpCell'],
    filter: 'agSetColumnFilter',
  }),
  new Column({
    field: 'nrsc_receive_date',
    headerName: 'NRSC Receive Date',
    width: 162,
    type: ['nrscReceiveDate', 'dateEditor', 'popUpCell'],
    filter: 'agDateColumnFilter',
  }),
  new Column({
    field: 'in_store_date',
    headerName: 'In-Store Date',
    width: 162,
    type: ['inStoreDate', 'dateEditor', 'popUpCell'],
    filter: 'agDateColumnFilter',
  }),
  new Column({
    field: 'pog_selection',
    type: ['hiddenColumn'],
  }),
  new Column({
    headerName: 'POG Set Dates',
    field: 'pog_set_date.set_dates',
    type: ['pogSetDatesExport', 'hiddenColumn'],
  }),
  new Column({
    field: 'divisions',
    colId: 'divisions',
    headerName: 'Project Divisions\u002A',
    width: 235,
    type: ['divisionCell', 'divisionsEditor', 'popUpCell', 'requiredField'],
    filter: 'agSetColumnFilter',
  }),
  new Column({
    field: 'departments',
    colId: 'departments',
    headerName: 'Project Departments\u002A',
    width: 262,
    type: ['departmentCell', 'departmentEditor', 'requiredField'],
    filter: 'agSetColumnFilter',
  }),
  new Column({
    field: 'divisions',
    colId: 'divisionsExport',
    headerName: 'Project Divisions\u002A',
    type: ['divisionExportCell', 'hiddenColumn'],
  }),
  new Column({
    field: 'departments',
    colId: 'departmentExport',
    headerName: 'Project Departments\u002A',
    type: ['departmentExportCell', 'hiddenColumn'],
  }),
  new Column({
    field: 'import_sap_project_ids',
    colId: 'import_sap_project_ids',
    headerName: 'Previous Projects',
    type: ['previousProjectsEditor'],
    width: 200,
  }),
]

export const exportColumns = [
  'sap_project_id',
  'name',
  'set_date',
  'short_name',
  'project_type',
  'campaign.name',
  'campaign_id',
  'initial_budget',
  'tactic',
  'fund_id',
  'creative_assets',
  'non_adjacency_project',
  'pog_set_date.set_dates',
  'nrsc_receive_date',
  'in_store_date',
  'divisionsExport',
  'departmentExport',
  'import_sap_project_ids',
]
