import moment from 'moment'
import {
  IRowDragItem,
  RowClassParams,
  ValueGetterParams,
  ValueFormatterParams,
} from 'ag-grid-community'
import { PROJECT_STATUS } from '../constants/blueprintProjectListConstants'
import { UserPermissions } from '../../../../../models/app/UserPermissions.model'
import {
  NEW_SIGN_PROJECT,
  SapProjectExecutionStatus,
} from '../../SignProjectTab/constants/signProjectConstants'
import { BLUEPRINT_PROJECT_LIST_VIEW } from 'components/App/constants/appConstants'
import SapTableProject from '../../../../../models/projects/SapTableProject.model'
import { Division } from '../../../../../models/merchandise/hierarchy/Division.model'
import { Department } from '../../../../../models/merchandise/hierarchy/Department.model'
import {
  CheckCircleFilledIcon,
  ErrorFilledIcon,
  HelpIcon,
} from '@enterprise-ui/icons'

export const sapStatusMessage = (
  status: SapProjectExecutionStatus,
  message: string,
) => {
  switch (status) {
    case SapProjectExecutionStatus.SUCCESS:
      return 'In sync with SAP'
    case SapProjectExecutionStatus.PENDING:
      return 'Pending sync with SAP'
    case SapProjectExecutionStatus.FAILURE:
    case SapProjectExecutionStatus.CRITICAL:
      return message

    default:
      return 'Pending sync with SAP'
  }
}

export const sapStatusIconColor = (status: SapProjectExecutionStatus) => {
  switch (status) {
    case SapProjectExecutionStatus.SUCCESS:
      return '#008300'
    case SapProjectExecutionStatus.PENDING:
      return '#e2ba0c'
    case SapProjectExecutionStatus.FAILURE:
    case SapProjectExecutionStatus.CRITICAL:
      return '#b92c09'

    default:
      return '#e2ba0c'
  }
}

export const sapStatusIcon = (status: SapProjectExecutionStatus) => {
  switch (status) {
    case SapProjectExecutionStatus.SUCCESS:
      return CheckCircleFilledIcon
    case SapProjectExecutionStatus.PENDING:
      return HelpIcon
    case SapProjectExecutionStatus.FAILURE:
    case SapProjectExecutionStatus.CRITICAL:
      return ErrorFilledIcon

    default:
      return HelpIcon
  }
}

export const divisionExportValueGetter = (params: ValueGetterParams) =>
  params.data.divisions
    .map((division: Division) => division.division_display_name)
    .join(', ')

export const departmentExportValueGetter = (params: ValueGetterParams) =>
  params.data.departments
    .map((dept: Department) => dept.department_display_name)
    .join(', ')

export const projectSetDatesValueGetter = (params: ValueGetterParams) =>
  params.data.pog_set_date.set_dates.length > 0
    ? params.data.pog_set_date.set_dates
        .map((date: string) => moment(date).format('MM/DD/YYYY'))
        .join(', ')
    : moment(params.data.set_date).format('MM/DD/YYYY')

export const setDateValueGetter = (params: ValueGetterParams) =>
  params.data?.set_date ? moment(params.data.set_date).format('MM/DD/YYYY') : ''

const PROJECT_LIST_DATE_FORMAT = 'MM/DD/YYYY'

export const dateFormatter =
  (key: string, format?: string) => (params: ValueFormatterParams) =>
    params.data[key] &&
    moment(params.data[key]).format(format || PROJECT_LIST_DATE_FORMAT)

export const dateComparator = (value1: string, value2: string) => {
  const _dateToNum = (date: string) => {
    if (date === undefined || date === null || date.length !== 10) {
      return null
    }
    return (
      parseInt(date.substring(6, 10)) * 10000 +
      parseInt(date.substring(0, 2)) * 100 +
      parseInt(date.substring(3, 5))
    )
  }
  const date1 = _dateToNum(value1)
  const date2 = _dateToNum(value2)
  return date1 === null && date2 === null
    ? 0
    : date1 === null
    ? -1
    : date2 === null
    ? 1
    : date1 - date2
}

export const dateFilterParams = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    if (cellValue === null) {
      return -1
    }
    const cellDate = new Date(cellValue)
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1
    }
  },
  browserDatePicker: true,
}

export const filterProjectListByView = (
  view: BLUEPRINT_PROJECT_LIST_VIEW,
  projects: SapTableProject[],
) =>
  view === BLUEPRINT_PROJECT_LIST_VIEW.ACTIVE
    ? projects.filter(
        (project: SapTableProject) =>
          project.workflow.status !== PROJECT_STATUS.ARCHIVE &&
          project.workflow.status !== PROJECT_STATUS.CANCEL,
      )
    : projects.filter(
        (project: SapTableProject) =>
          project.workflow.status === PROJECT_STATUS.ARCHIVE ||
          project.workflow.status === PROJECT_STATUS.CANCEL,
      )

export const pogSelectionValueGetter = (params: ValueGetterParams) => {
  return params.data.pog_selection
}

export const cellIsEditable = (
  project: SapTableProject,
  field: string,
  userPermissions: UserPermissions,
  signUploadFeature?: any,
) => {
  const userCanEdit =
    userPermissions.isProjectAdmin || userPermissions.isSAPVendor

  if (
    project.workflow.status === PROJECT_STATUS.ARCHIVE ||
    project.workflow.status === PROJECT_STATUS.CANCEL ||
    project.toBeCancelled
  ) {
    return false
  }

  if (field === 'import_sap_project_ids') {
    return signUploadFeature;
  }

  if (['pog_selection', 'divisions', 'departments'].includes(field)) {
    return userCanEdit || userPermissions.isKitEditor
  }

  if (['pog_set_date'].includes(field)) {
    return userCanEdit || userPermissions.isSignEditor
  }

  return [
    'set_date',
    'nrsc_receive_date',
    'short_name',
    'project_type',
    'campaign',
    'initial_budget',
    'tactic',
    'fund_id',
    'creative_assets',
    'non_adjacency_project',
    'in_store_date',
    'divisions',
    'departments',
  ].includes(field)
    ? userCanEdit
    : false
}

export const rowClassRules = {
  'ag-row-highlight new-row': (params: RowClassParams) =>
    params.data.project_id?.split(':')[0] === NEW_SIGN_PROJECT,
  'ag-row-highlight cancelled-row': (params: RowClassParams) =>
    params.data.toBeCancelled,
}

export const rowDragText = (params: IRowDragItem, dragItemCount: number) =>
  dragItemCount > 1
    ? `${dragItemCount} Projects`
    : params.rowNode?.data.short_name
