import { get } from 'lodash'
import moment from 'moment'
import { pogSelectionValues } from '../../components/BlueprintDetails/components/BlueprintProjectList/constants/blueprintProjectListConstants'
import { SapProjectExecutionStatus } from '../../components/BlueprintDetails/components/SignProjectTab/constants/signProjectConstants'
import { Department } from '../merchandise/hierarchy/Department.model'
import { Division } from '../merchandise/hierarchy/Division.model'
import { SAPProject } from './SAPProject.model'
import { PROJECT_STATUS } from '../../components/BlueprintDetails/components/BlueprintProjectList/constants/blueprintProjectListConstants'
import { BlueprintCampaign } from '../signs/SignResponse.model'

export default class SapTableProject {
  blueprint_id: string
  project_id?: string
  name?: string
  short_name: string
  campaign_id?: string
  creative_assets: boolean
  non_adjacency_project: boolean
  set_date: string
  nrsc_receive_date?: string
  in_store_date?: string
  pog_set_date: PogSetDate
  pog_selection: pogSelectionValues
  divisions: Division[]
  departments: Department[]
  project_type?: string
  tactic?: string
  fund_id?: string
  fund_name?: string
  initial_budget?: number
  sap_project?: SAPProject
  sap_project_id?: string
  sap_project_execution_status: SapProjectExecutionStatus
  sap_project_execution_message: string
  campaign: BlueprintCampaign
  migrated: boolean
  workflow: ProjectWorkflow
  toBeCancelled: boolean
  hasError: boolean
  modified: boolean
  created_by: string
  created_date: Date
  updated_by: string
  updated_date: Date
  import_sap_project_ids?: string[]

  constructor(props: any = {}) {
    this.blueprint_id = props.blueprint_id || ''
    this.project_id = props.project_id
    this.name = props.name
    this.short_name = props.short_name || ''
    this.campaign_id = props.campaign_id
    this.creative_assets = props.creative_assets || false
    this.non_adjacency_project = props.non_adjacency_project || false
    this.nrsc_receive_date =
      props.nrsc_receive_date &&
      moment(props.nrsc_receive_date).format('YYYY-MM-DD')
    this.set_date =
      moment(props.set_date).format('YYYY-MM-DD') ||
      moment(new Date()).format('YYYY-MM-DD')
    this.in_store_date =
      props.in_store_date && moment(props.in_store_date).format('YYYY-MM-DD')
    this.pog_set_date = props.pog_set_date
      ? new PogSetDate(props.pog_set_date)
      : new PogSetDate()
    this.pog_selection =
      props.pog_selection || props.pog_set_date
        ? props.pog_set_date.use_marketing_plan_date
          ? pogSelectionValues.MARKETING_PLAN_DATE
          : props.pog_set_date.use_multiple_set_date
          ? pogSelectionValues.MULTIPLE_SET_DATES
          : pogSelectionValues.PROJECT_SET_DATE
        : pogSelectionValues.PROJECT_SET_DATE
    this.divisions = get(props, 'divisions', []).map(
      (division: Division) => new Division(division),
    )
    this.departments = get(props, 'departments', []).map(
      (dept: Department) => new Department(dept),
    )
    this.project_type = props.project_type
    this.tactic = props.tactic
    this.fund_id = props.fund_id
    this.fund_name = props.fund_name
    this.initial_budget = props.initial_budget
    this.sap_project = props.sap_project && new SAPProject(props.sap_project)
    this.sap_project_id = props.sap_project_id
    this.sap_project_execution_status =
      props.sap_project_execution_status || SapProjectExecutionStatus.PENDING
    this.sap_project_execution_message =
      props.sap_project_execution_message || 'Pending sync with SAP'
    this.campaign = props.campaign
      ? new BlueprintCampaign(props.campaign)
      : new BlueprintCampaign()
    this.migrated = props.migrated || false
    this.workflow = props.workflow
      ? new ProjectWorkflow(props.workflow)
      : new ProjectWorkflow()
    this.toBeCancelled = props.toBeCancelled || false
    this.hasError = props.hasError || false
    this.modified = props.modified || false
    this.created_by = props.created_by || 'system'
    this.created_date = props.created_date || new Date()
    this.updated_by = props.updated_by || 'system'
    this.updated_date = props.updated_date || new Date()
    this.import_sap_project_ids = props.import_sap_project_ids
  }
}

export class PogSetDate {
  use_marketing_plan_date: boolean
  use_multiple_set_date: boolean
  set_dates: string[]
  constructor(props: any = {}) {
    this.use_marketing_plan_date = props.use_marketing_plan_date || false
    this.use_multiple_set_date = props.use_multiple_set_date || false
    this.set_dates = get(props, 'set_dates', []).map((setDate: number) =>
      moment(setDate).format('YYYY-MM-DD'),
    )
  }
}

export class ProjectWorkflow {
  status: PROJECT_STATUS
  color?: string
  reasons?: string[]

  constructor(props: any = {}) {
    this.status = props.status || PROJECT_STATUS.PENDING
    this.color = props.color
    this.reasons = props.reasons || []
  }
}
