import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Form, Grid } from '@enterprise-ui/canvas-ui-react'

export interface Props {
  value?: string[]
}

const PROJECT_ID_REGEX = /^C-\d{6}-\d{2}-\d{3}$/

const processInput = (input: string): string[] => {
  return input
    .split(/[,\s\n]+/)
    .map((word) => word.trim())
    .filter(Boolean)
}

const PreviousProjectsCell = forwardRef(({ value }: Props, ref) => {
  const [editValue, setEditValue] = useState((value ?? []).join(', '))
  const [error, setError] = useState<string | null>(null)
  const refInput = useRef<HTMLInputElement>(null)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setEditValue(newValue)

    const projects = processInput(newValue)
    const invalidProjects = projects.filter((id) => !PROJECT_ID_REGEX.test(id))

    if (invalidProjects.length > 0) {
      setError('Invalid project ID(s) detected.')
    } else {
      setError(null)
    }
  }

  useEffect(() => {
    setTimeout(() => refInput?.current?.focus(), 0)
  }, [])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return processInput(editValue)
      },
      isPopup() {
        return true
      },
    }
  })

  return (
    <div className="hc-pa-dense">
      <Grid.Container className="sem_StandardForm error_messaging_enabled">
        <Grid.Item className="hc-pb-normal" xs={12}>
          <Form.Field
            id="previous_projects_cell"
            ref={refInput}
            value={editValue}
            onChange={handleChange}
            size="dense"
            error={!!error}
            errorText={error || ''}
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default PreviousProjectsCell
